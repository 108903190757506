
.side-nav {
    width: 360px;
    max-height: 830px;
    background-color: #fff;
    padding: 20px;
    /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
   
  }
  
  .profile {
    text-align: center;
    position: relative;
  }
  
  .profile img {
    width: 130px;
    height: 135px;
    border-radius: 50%;
    margin-bottom: 10px; 
  }
  
  .admin-tag {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(2, 192, 151);
    color: white;
    padding: 6px 14px;
    border-radius: 14px;
    font-size: 12px;
  }
  
  .profile h3 {
    margin: 15px 0 5px;
  }
  
  .profile p {
    margin-top: 10px;
  }
  
  .rank {
    font-weight: bold;
    color: #333;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 10px 0;
  }
  
  .stat-item {
    text-align: center;
  }
  
  .points-earned {
    display: flex; 
    align-items: center; 
    font-weight: bolder;
  }
  
  .points-label {
    margin-right: 10px; 
    text-align: left;
    align-items: left;
    font-weight: bold;
    color: rgb(56, 56, 57);
  }

  .points{
    font-size: 25px;
  }
  
  
  .nav-items {
    list-style: none;
    padding: 0;
    width: 100%;
    font-weight: 500;
  }
  
  .nav-items li {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .nav-items li:hover,
  .nav-items li.active {
    background-color: #f0f0f0;
  }

  .nav-items li .fa-icon {
    margin-right: 10px; 
  }
  
  .nav-items li i {
    margin-right: 10px;
  }

  .icon-text {
    margin-left: 20px; 
  }

  .nav-items li a {
     text-decoration: none;
   
  }
  
  
  .logout-button {
    color: rgb(247, 35, 35);
    border: none;
    cursor: pointer;
   
  }
  
  .logout-button:hover {
    background-color: #ff3333;
  }
  
  .horizontal-line{
    width: 100%;
    
    border-color: rgba(236, 233, 233, 0.226);
  }
  
  a {
    text-decoration: none; 
    color: inherit; 
  }
  