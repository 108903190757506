

.add-discussion {
    position: absolute;
    padding: 20px;
    max-height: 2000px;
    max-width: 2000px;
    border-radius: 7px;
    background-color: #fff;
    margin-bottom: 20px;
    margin-top: 80px;
    border-radius: 10px;
    margin-left: 450px;
    width: 68%;
    padding-left: 20px;
    height : 800px
 
   
    
  }
  
  .discussion-title {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .divider {
    margin: 10px 0;
    border: 0;
    border-top: 1px solid #cccccc;
  }
  
  .form-group {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input[type="text"],
  .form-group textarea
   {
    width:96%;
    padding: 15px;
    padding-right: 20px;
    border: none;
    border-radius: 8px;
    background-color: whitesmoke;
  }

  .form-group select {
    width: 50%;
    padding: 15px;
    padding-right: 20px;
    border-radius: 8px;
    border-color: rgba(0, 0, 0, 0.166);
    background-color: white;
   
  }

  .description-input {
    /* height: 1000px;  */
  }
  
  .radio-group {
    display: flex;
    flex-direction: row;
    gap: 20px; 
  }
  
  .radio-group label {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .radio-group input[type="radio"] {
    margin-right: 8px; 
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancel-button {
    background-color: #f0f0f0;
    color: #2b2a2a;
    /* border: 1px solid #cccccc; */
    border: none;
    padding: 15px 20px;
    border-radius: 8px;
    cursor: pointer;
    
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .input-file{
    /* padding: 15px 20px; 
    border:  rgba(0, 0, 0, 0.185); 
    border-radius: 8px; 
    background-color: rgb(26, 26, 26); 
   
    cursor: pointer;
   
    font-size: 16px; 
    width: 200px; 
    line-height: 1.5;  */
  }

  .buttons-group{
    display: flex; 
  align-items: center; 
  justify-content: space-between;
  }

  input[type="text"] {
    border: none; 
    outline: none; 
    /* box-shadow: none;  */
    /* padding: 0; 
    margin: 0;  */
    /* font-size: inherit; 
    background: none; */
  }
  