.top-nav {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 60px;
    background-color: white;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    width: 100%;
    position: absolute;
    
  }
  
  .top-nav h2 {
    font-size: 24px;
    font-weight: bold;
    margin-left:21%;
    color: #333;
  }

  .addButton{
    margin-left: 59%;
    background-color: white;
    color: rgb(66, 66, 255);
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    border-color: rgb(66, 66, 255);
  }


  