
.no-discussions-container {
    text-align: center;
    padding: 20px;
    /* display: flex; */
    align-items: flex-start;
    padding: 20px;
    max-height: 780px;
    max-width: 900px;
    border-radius: 7px;
    background-color: #fff;
    margin-bottom: 20px;
    margin-top: 80px;
    border-radius: 10px;
    margin-left: 20px;
    width: 970px;
  }
  
  .no-discussions-icon img {
    width: 90px;
    height: 90px;
    margin-bottom: 20px;
    margin-top: 200px;
  }
  
  .no-discussions-title {
    font-size: 30px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .no-discussions-message {
    font-size: 23px;
    color: #333;
    margin-bottom: 20px;
    padding-left: 250px;
    padding-right: 250px;
    font-weight: medium;
  }
  
  .new-discussion-button {
    margin-top: 60px;
    background-color: #007bff;
    color: white;
    padding: 15px 27px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .new-discussion-button i {
    margin-right: 5px;
  }
  
  .new-discussion-button:hover {
    /* background-color: #0056b3; */
  }
  
  .no-discussions-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px ;
    width: 450px;
    margin-left: 25%;
    margin-top: 50px;
    
  }
  
  .no-discussions-divider .line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
    margin: 0 10px;
    width: 20px;
  }
  
  .no-discussions-divider .or {
    font-size: 20px;
    color: #666;
  }