
    .main-container{
     background-color: whitesmoke;
     /* align-items: center; */
     display: flex;
     /* justify-content: center;  */
     /* align-items: center;  */
     /* min-height: 700vh;  */
     /* padding: 20px; */
     /* height: auto; */

    }

.all-discussions {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    max-width: 8000px;
    max-height: 780px;
    margin-left: 20px;
    margin-top: 80px;
    width:20% ;
    height : 10%;
    flex-direction: column; 
    /* overflow: hidden; */
    overflow-y: auto; 
    height: auto; 
    position: relative;
    display: flex;
    overflow: hidden;
    position: relative;
}

.all-discussions::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px; 
}

.all-discussions h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.search-bar-container {
    /* position: relative;  */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    height: 100px;
    margin-top: 0%;
    position: sticky;
    top: 0; 
    background-color: #fff; 
    /* z-index: 1; */
    z-index: 10; 
     
    
}

.all-discussions-container{
    overflow-y: auto;
    width:100% ; 
       
}

.search-bar input {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

ul {
    list-style-type: none;
    padding: 0;
}

.search-icon{
    position: absolute;
    display: flex;
    margin-left: 90%;
    cursor: pointer;
    /* margin-top: 20px; */
    
}


.search-bar-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    cursor: pointer;
    margin-right: 10px; 
  } 
  
  .search-bar {
    display: block;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 90%; 
    position: absolute;
    left: 0;
    top: 85%; 
    z-index: 0; 
    background: whitesmoke; 
  }
  
  .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
    margin-left: 10px; 
  }
  
