.main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    flex-direction: column;
  }
  .user-profile-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height:50px;
    background-color: rgb(162, 25, 169);
    color: #ffffff;
    font-size: 25px;
    font-family: sans-serif;
    flex-shrink: 0;
    
    
  }