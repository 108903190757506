.discussion-item {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.discussion-item:last-child {
    border-bottom: none;
}

.discussion-avatar {
    margin-right: 10px;
}

.discussion-avatar img {
    border-radius: 50%;
    width: 50px;
    height:50px;
}

.discussion-info {
    flex: 1;
    margin-left: 0%;
    margin-right: 35%;
}

.discussion-header {
    /* display: flex; */
    /* justify-content: space-between; */
    font-size: 14px;
}

.discussion-name {
    font-weight: bold;
}

.discussion-time {
    color: #aaa;
    margin-top: 0px;
}

.discussion-topic {
    margin: 5px 0;
    font-size: 15px;
    font-weight: 500;
}

.discussion-comments {
    font-size: 12px;
    color: #666;
    margin-left: 1%;
    margin-top: 0%;
}

