
.dropdown {
    position: relative;
    display: inline-block;
    
  }

  .dropdown-section{
    display: flex;
   
  }

  .dropdown-arrow {
    margin-left: 20px;
    margin-top: 25px;
    color: darkgray;
  }
  
  .dropdown-header {
    cursor: pointer;
    user-select: none;
    position: relative;
    
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50px; 
    /* left: 0; */
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 200px;
    z-index: 1000;

    margin: 0;
    list-style: none;
    padding-bottom: 12px;
    padding-right: 40px;
    max-height: 500px; 
    overflow-y: auto;
  }
  
  .dropdown-item {
    padding: 8px 15px;
    cursor: pointer;
    user-select: none;
   
   
  }
  
  .dropdown-item:hover {
   
  }
  
  .dropdown-item.selected {
  
    font-weight: 500;
  }

  .check-icon {
    color: green;
    margin-left:10px;
  }
  