.single-discussion-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 20px;
    max-height: 780px;
    max-width: 900px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 20px;
    margin-top: 80px;
    border-radius: 10px;
    margin-left: 20px;
    width: 970px;
    resize: both;
    overflow: auto; 
    /* overflow: y; */
    align-self:center;

   
  }
  
  /* .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 10px;
  }
   */
  .discussion-content {
    flex-grow: 1;
  }
  
  .discussion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .time-ago {
    color: #888;
    font-size: 14px;
    margin-top: 42px;
    margin-left: 6.5%;
  }
  
  .discussion-title {
    margin: 0;
    font-size: 18px;
    font-weight: bolder;
    color: #333;
    /* margin-top: 10px; */
  }
  
  .discussion-description {
    margin: 10px 0;
    color: #333;
    font-weight:600;
    white-space: normal;
    overflow: visible; 
    word-wrap: break-word;
    max-width: 65%;

  }
  
  .discussion-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    font-size: 14px;
    color: #0a0b0b;
    background: none;
    background-color: #f3f1f1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    width: 100px;
    
  }
  
  
  
  .horizontal-line-comments{
    margin-top: 20px;
        width: 100%;
        border-color: rgba(255, 255, 255, 0.217);
       
      
  }

  

  .no-comments{
    display: flex;
    color: #888;
    font-weight: 400;
   
    align-items: center;
  }

  .profile-details {
    display: flex;
    position: absolute;
    align-items: center;
   
  }
  
  .singleUserName {
    display: flex;
    white-space: nowrap; 
    /* overflow: hidden; */
    text-overflow: ellipsis;
    max-width: 150px; 
    margin-left: 10%;

  }

  .no-comments-users{
   
    display: flex;
    white-space: nowrap; 
    /* overflow: hidden; */
    text-overflow: ellipsis;
    max-width: 150px; 
   

  }
  
  .no-comments-p{
    padding-left: 47rem;
    position: absolute;
 
  }

  .horizontal-line-comments-count{
        /* margin-top: 30px;
        margin-bottom: 30px; */
        width: 100%;
        border-color: rgba(255, 255, 255, 0.217);
  }