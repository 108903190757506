.profile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 10px;
  }
  
  .user-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 10px;
    
  }
  