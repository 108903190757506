.comment-section {
    padding-left: 16px;
    height: auto;
 
   
    
  }
  
  .comments-list {
    max-height: 300px;
    overflow-y: auto;
    max-width: 50rem;
    
  }
  
  .comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  
  .comment-user-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .comment-content {
    background: #fefdfd;
    padding: 10px;
    border-radius: 8px;
    max-width: 65rem;
    width:90%;
  }
  
  .comment-user-name {
    font-weight: bold;
  }
  
  .comment-text {
    margin: 8px 0;
    white-space: normal;
    overflow: visible; 
    word-wrap: break-word;
    /* max-width: 100%; */
    width:100%;
  }
  
  .comment-attachment {
    max-width: 100px;
    max-height: 100px;
    margin-top: 8px;
  }
  
  .comment-time {
    color: #999;
    font-size: 12px;
  }
  
  .new-comment {
    margin-top:4%;
  }
  
  .comment-input-wrapper {
    position: relative;
    margin-bottom: 5px;
    max-width: 100%;
  }
  
  .comment-input {
    width: 50rem;
    height: 40px;
    padding: 10px;
    padding-right: 100px; 
    border-radius: 10px;
    border:none;
    box-sizing: border-box;
    background-color: whitesmoke;
    margin-left: 50px;

    border: none;
    overflow: auto;
    outline: none;
  
  }
  
  .comment-actions {
    position: absolute;
    top: 10px;
    /* right: 1px; */
    display: flex;
    gap: 10px;
    margin-left: 40rem;
  }
  
  .emoji-picker button, .add-attachments {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .emoji-picker button {
    font-size: 24px;
  }
  
  .add-attachments {
    color: #131313;
    font-weight: bold;
    background-color: white;
    margin-bottom: 20px;
    padding: 6px 8px;
    border-radius: 10px;
  }
  
  .user-pic{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .prevCommentsHeader{
    font-size: medium;
    color: #999;
  }

  /* .load-more {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .load-more:hover {
    background-color: #0056b3;
  } */
  